<template>
    <section class="gardenManagementPage">
       

        <SearchForm class="dis-flex flex-x-between flex-y-center">

            <el-form ref="ref_searchForm" :inline="true" :model="searchForm" label-width="80px">
                <el-form-item>
                    <el-select v-model="searchForm.type" placeholder="请选择餐品类型" @change="changeDish" clearable @clear="handleClearType">
                        <el-option v-for="(item, index) in dishList" :key="index" :label="item.name" :value="item.name" />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="searchForm.payState" placeholder="请选择支付类型" @change="changePay" clearable @clear="handleClearPay" >
                        <el-option v-for="(item, index) in payList" :key="index" :label="item.name" :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker v-model="dayTime" type="datetimerange" range-separator="至" start-placeholder="开始时间"
                        end-placeholder="结束时间" format="yyyy-MM-dd" data-format="yyyy-MM-dd" @change="selectTime">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-input v-model.trim="searchForm.userName" placeholder="请输入姓名" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="queryDcUserInfoPage">搜 索</el-button>
                </el-form-item>
            </el-form>
            <download-excel :data="jsonData" worksheet="报餐人员表格" name="报餐人员表格.xls" class="excel">
                <el-button type="primary" @click="down">导出EXCEL</el-button>
            </download-excel>
        </SearchForm>

        <TableContainer title="">
            <el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader"
                :cell-style="{ textAlign: 'center' }">
                <el-table-column prop="userId" label="员工id" min-width="60" />
                <el-table-column prop="fullName" label="姓名" min-width="60" />
                <el-table-column prop="dayTime" label="日期" min-width="60" />
                <el-table-column prop="roleName" label="部门" min-width="60" />
                <el-table-column prop="type" label="类型" min-width="60" />
                <el-table-column prop="overtimeName" label="是否加班" min-width="100" />
                <el-table-column prop="price" label="价格" min-width="60" />
                <el-table-column prop="orderNo" label="订单号" min-width="100" />
                <el-table-column prop="payStateName" label="支付状态" min-width="100" />
                <el-table-column prop="mealsStateName" label="用餐状态" min-width="100" />
                <el-table-column width="150" label="操作">
                    <template slot-scope="scope">
                        <button  type="info" plain @click="approveFormClick(scope.row)" v-show="scope.row.cancel=='0'"> 消餐</button>
                    </template>
                </el-table-column>
            </el-table>
            <GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
                :size.sync="searchForm.size" @pagination="queryDcUserInfoPage" />
        </TableContainer>






    </section>
</template>

<script>
import { parseTime } from '@utils/filters'
import {
    queryDcUserInfoPage,
    userInfoCancel
} from '@api/bx/api_contentManagement';
export default {
    data() {
        return {
            themeDialog: {
                title: '信息框',
                visible: false
            },
            searchForm: {
                page: 1,
                size: 10,
                userName: "",
                startTime: '',
                endTime: '',
                type: '',
                payState: ''


            },
            total: 0,
            tableData: [],
            typeList: [],
            dishList: [
                // {name:'早餐',id:0},
                { name: '中餐', id: 1 },
                { name: '晚餐', id: 2 },
            ],
            payList: [
                { name: '已支付', id: 1 },
                { name: '加班(无需支付)', id: 2 },
            ],
            dayTime: '',
            dishName: '',
            payName: '',
            downData: [],
            jsonData: []

        }
    },
    created() {
        this.dayTime = [parseTime(new Date(), '{y}-{m}-{d}'), parseTime(new Date(), '{y}-{m}-{d}')]
        this.queryDcUserInfoPage()

    },
    methods: {
        tabHeader() {
            return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
        },
        handleClearType(){
            this.searchForm.type=''
            this.queryDcUserInfoPage()
        },
        handleClearPay(){
            this.searchForm.payState=''
            this.queryDcUserInfoPage()
        },

        //时间
        selectTime(msg) {
            this.dayTime = [parseTime(msg[0], '{y}-{m}-{d}'), parseTime(msg[1], '{y}-{m}-{d}')]
            this.queryDcUserInfoPage()

        },

        //餐品类型
        changeDish() { 
            this.queryDcUserInfoPage()
        },

        //支付
        changePay() {
            this.queryDcUserInfoPage()

        },

        // 列表
        queryDcUserInfoPage() {
            // console.log('55585555')
            this.searchForm.startTime = this.dayTime[0]
            this.searchForm.endTime = this.dayTime[1]
            queryDcUserInfoPage(this.searchForm).then(res => {
                this.tableData = res.records
                this.total = res.total
                this.downList()

            })
        },
        // 列表
        downList() {
            this.searchForm.startTime = this.dayTime[0]
            this.searchForm.endTime = this.dayTime[1]
            queryDcUserInfoPage({
                userName: this.searchForm.userName,
                startTime: this.searchForm.startTime,
                endTime: this.searchForm.endTime,
                type: this.searchForm.type,
                payState: this.searchForm.payState,
            }).then(res => {
                this.downData = res.records

            })
        },
        down() {
            this.jsonData = []
            for (var i of this.downData) {
                this.jsonData.push({
                    "姓名": i.fullName,
                    "日期": i.dayTime,
                    "部门": i.roleName,
                    "类型": i.type,
                    "是否加班": i.overtimeName,
                    "价格": i.price,
                    "订单号": i.orderNo,
                    "支付状态": i.payStateName,
                    "用餐状态": i.mealsStateName,
                })

            }
        },

        //消餐
        approveFormClick(row) {
            this.$confirm(row.fullName + '是否确定消餐?', '提示', {
                confirmButtonText: '确定消餐',
                cancelButtonText: '取消',
                type: 'warning'
            }).then((res) => {
                userInfoCancel({
                    userId: row.userId,
                    dayTime: row.dayTime,
                    type: row.type,
                }).then(res => {
                    this.$message.success(row.fullName + '消餐成功')
                    this.queryDcUserInfoPage()
                })

            })
        },



    }
}
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.turnBule {
    color: #fff;
    background-color: #58e3e8;
    border-color: rgba(56, 185, 190, 0.12);
    margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
    background: rgba(56, 185, 190, 0.5);
    border-color: rgba(56, 185, 190, 0.5);
    color: #fff;
}

.el-button--text.reviewed {
    color: #38b9be;
}

.reviewed {
    color: #38b9be;
}

.el-tree-node__content {
    z-index: 999;
    padding-left: 40px;

}

.directory-in {
    width: 32%;
    line-height: 60px;
    background: #4095E5;
}
</style>

